import React from 'react';
import ReactDOM from 'react-dom';
import Home from './page/Home'
import { 
  BrowserRouter as Router,
  Route
} from 'react-router-dom';

const routing = (
  <Router>
      <Route exact path="/" component={Home} />
      <Route exact path="/projects" component={Home} />
  </Router>
)

ReactDOM.render(routing, document.querySelector('#root'));
