import React from 'react';

import ImageBox from './ImageBox';
import SocialsBox from './SocialsBox';

export default function HomeSplashScreen() {

    const homeBackground = "https://images.unsplash.com/photo-1510511459019-5dda7724fd87?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80"

    return (
        <>
            <ImageBox className="home" url={homeBackground}>
                <SocialsBox className="avatar-box" />
            </ImageBox>
        </>
    )
}