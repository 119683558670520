import React from 'react';

export default function AvatarBox({socials, className}) {
    return(
        <div className={className}>
            {socials.map(social => (
                <a href={social.url} target="_blank" key={social.url}>
                    <img key={social.image} className='avatar' src={social.image} alt="" /> 
                </a>
            ))}
        </div>
    )
}