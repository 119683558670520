import React from 'react';
import '../assets/styles/SocialsBox.css';
import Images from '../assets/images/index';
import AvatarBox from './AvatarBox';

export default function SocialsBox({className}) {
    return (
        <div className="socials">
            <div className="profile">
                <h1>MACAULAY HOWARTH</h1>
                <p>Software Engineer</p>
            </div>
            <AvatarBox className={className} socials={Images}/>
        </div>
    )
}