import React from 'react';
import '../assets/styles/Home.css';
import AboutMeProfile from '../components/AboutMeProfile';
import AboutMe from '../assets/content/about-me.json';
import Layout from '../components/Layout';
import HomeSplashScreen from '../components/HomeSplashScreen';
import DocViewer from '../components/DocViewer';

export default function Home() {
    return (
        <>
            <Layout>
                <HomeSplashScreen />
                {/* <AboutMeProfile className="about-me-home" text={AboutMe}/> */}
                <DocViewer/>
            </Layout>
        </>
    )
}