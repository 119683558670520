
import Github from './github.png';
import Insta from './insta.png';
import Linkedin from './linkedin.png';
import Twitter from './twitter.png';
import Youtube from './youtube.png';

export default [
    {
        image : Github,
        url : 'https://github.com/Macasacker'
    },
    {
        image: Insta,
        url : 'https://www.instagram.com/mclyhowarth/'
    },
    {
        image : Linkedin,
        url : 'https://www.linkedin.com/in/mac-howarth-ab1489165/'
    },
    {
        image : Twitter,
        url : 'https://twitter.com/Macasacker'
    },
    {
        image : Youtube,
        url : 'https://www.youtube.com/user/vIMaCv'
    }
];