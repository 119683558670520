import  React from 'react';
import '../assets/styles/DocViewer.css';

export default function DocViewer() {
    
    return(
        <div className="doc-viewer">
            <iframe src="https://drive.google.com/file/d/17ortPfRwp71qiX3l75kk4HgIzcQHr-uo/preview" width="100%" height="800" allow="autoplay"></iframe>
        </div>
    );
    
}