import React from 'react';
import '../assets/styles/Footer.css';
import AvatarBox from './AvatarBox';
import Images from '../assets/images/index';

export default function Footer() {
    return (
        <div className="footer">
            <p className="email">
                macaulayhowarth@hotmail.com
            </p>
            <AvatarBox className="avatar-box-footer" socials={Images}/>
        </div>
    )
}