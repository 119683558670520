import React from 'react';
import '../assets/styles/ImageBox.css';

export default function ImageBox({className, url, children}) {

    return (
        <div id='imgbox' className={className}>
            <img src={url}  alt="" />
            {children}
        </div>
    )

}